
.galleryPage {
    width: inherit;
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background-color: #737B84;
    margin-top: 15px;
    padding: 15px;
}

.galleryThumb {
    width: 275px;
    background-color: #BCBDBB;
    margin: 7px;
    padding: 3px;
    border-radius: 7px;
}

.galleryThumb:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

* {
  box-sizing: border-box;
}

.thumbsFlow {
    width: 100%;
    margin: 5px;
    display: flex;
    flex-flow: row;
    justify-content: left;
    overflow: auto;
  }
  
  .thumbsFlowImg {
    width: 225px;
    margin: 3px;
    border-radius: 7px;
    opacity: 0.5;
    cursor: pointer;
  }

/* The Modal (background) */
.modal {
    position: fixed;
    z-index: 1;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #737B84;
  }
  
  /* Modal Content */
  .modalContent {
    position: relative;
    background-color: #c9cacc;
    margin: auto;
    padding: 0;
    width: 90%;
    /* Trying to find a width that allows caption to show.  Won't show on certain screen resolutions.  */
    /* Must learn how to calculate this! */
    max-width: 900px;
  }

/* The Close Button */
.close {
    color: #c9cacc;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }

  /* Number text (1/3 etc) */
.numbertext {
    color: #c9cacc;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  /* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  /*background-color: rgba(0, 0, 0, 0.8);*/
  background-color: #313131;
  opacity: 0.8;
}

.mySlides {
    color: whitesmoke;
}

.mySlides img {
    max-width: 100%;
    padding: 5px;
    border-radius: 15px;
}

img {
    margin-bottom: -4px;
  }
  
.captionContainer {
    text-align: center;
    background-color: #313131;
    padding: 2px 16px;
    color: white;
}

