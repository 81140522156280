
.blogPage {
    width: inherit;
    margin: 25px;
    padding: 15px;
    line-height: 1.75em;
}

hr {
    width: 50%;
    border-top: 1px dotted #313131;
    border-bottom: none;
}

article {
    display: block;
    width: inherit;
    height: fit-content;
    margin: 25px;
    padding: 15px;
}

article:after {
    content: "";
    display: table;
    clear: both;
}

.blogImgMultiLeft {
    width: 30%;
    margin: 20px 45px 20px 5px;
    float: left;
}

.blogImgMultiRight {
    width: 30%;
    margin: 20px 45px 20px 5px;
    float: right;
}

.blogImgMulti {
    display: flex;
    flex-direction: column;  
}

.blogImgMultiImg {
    width: 100%;
    margin: 5px;
}

.blogImgLeft {
    width: 30%;
    margin: 25px 50px 25px 10px;
    float: left;
}

.blogImgRight {
    width: 30%;
    margin: 25px 10px 25px 50px;
    float: right;
}

.blogImgDropdown {
    display: none;    
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 71vw; */
    /* height: 100vh; */
    background-color: #e7e7e7;    
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    z-index: 9;
}

.blogImgSm, .blogImgDropdown img {
    width: 100%;
    padding: 0px;
    border-radius: 3px;
}

.blogImgDropdown img {
    padding: 25px 25px 5px 25px;
}

.blogImgMultiImg:hover .blogImgDropdown {
    display: block;
    /* height: 100vh; */
}

.blogImgRight:hover .blogImgDropdown {
    display: block;
} 

.blogImgLeft:hover .blogImgDropdown {
    display: block;
} 

.blogCaption {
    padding: 0px 25px 15px 25px;
    text-align: center;
}
