/* #bcbdbb - Silky Stone */
/* #737b84 - Evening Shadow */

/* #c7c9cf - page background */

.layoutStyle {
    width: inherit;
    background-color: #c9cacc;
    padding: 25px 100px;
}

.h1 {
    font-size: 2.5em;
    text-align: center;
    margin: 0px;
    padding: 25px 0px;
}

pre {
    font-size: 1.1em;;
    font-style: italic;
}

nav {
    width: inherit;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    border-top: 1px solid #313131;
    border-bottom: 1px solid #313131;
}

.linkStyle {
    width: 125px;
    margin: 15px 25px;
    padding: 3px 7px;
    color: #313131;
    font-size: 1.3em;
    font-style: italic;
    text-align: center;
    text-decoration: none;
}

.linkStyle:hover {
    color: #c9cacc;
    background-color: #737b84;
    border-radius: 3px;
}
