
.homePage {
    width: inherit;
    height: auto;
    margin: 25px;
    padding: 15px;
    line-height: 1.75em;
}

.homePage:after {
    content: "";
    display: table;
    clear: both;
}

.homeImg {
    width: 45%;
    margin: 0px 10px 25px 50px;
    padding: 0px;
    border-radius: 3px;
    float: right;
    clear: right;
}
